@import 'formiojs/dist/formio.full.min.css';

.formio,
.formio-dialog {
  --bs-body-bg: #fff;
  --bs-body-color: #212529;
  --bs-border-width: 1px;
  --bs-border-color: #ced4da;
  --bs-border-radius: 0.375rem;
   
  @import '~bootstrap/scss/bootstrap.scss';

  .form-group {
    overflow-x: visible;
  }

  .component-btn-group .component-settings-button {
    padding: 2px 2px;
    font-size: 10px;
    line-height: 1.2em;
    border-radius: 0;
  }

  .choices__input {
    padding: 0;
  }

  .choices__item {
    overflow: inherit;
  }
}
