.react-tooltip {
  &.show {
    background-color: #404040 !important;
    opacity: 0.7 !important;
  }
  &.place-top {
    &:after {
      background-color: #404040 !important;
      opacity: 0.7 !important;
    }
  }
}
