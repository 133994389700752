@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'components/formio.scss';
@import 'components/custom-report.scss';
@import 'components/dropdown.scss';
@import 'components/monthly-savings.scss';
@import 'components/table.scss';
@import 'components/tooltip.scss';
@import 'tailwindcss/utilities';
@import 'utilities/transitions.scss';
@import 'utilities/rotate.scss';

.formio-component-datagrid {
    overflow-x: visible;
}

.formio-component {
    padding: 6px;
    padding-bottom: 12px;
}

.formio-component .col-form-label {
    font-size: 16px;
    color: rgb(134 142 150);
}

.formio-component .form-control {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgb(233 236 239);
    color: rgb(73 80 87);
    padding: 12px 10px;
}

.formio-read-only {
    .formio-button-add-row, .formio-button-remove-row, button[type=submit] {
        display: none;
    }
}

.project-details .formio {
    .role-select, .entity-extraction {
        display: none;
    }
}

.edit-project .role-select {
    display: none;
}

.formio .form-control:disabled {
    background: white;
}

.formio .form-control:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid rgb(233 236 239);
    box-shadow: none;
}

.formio .input-group-text {
    border-radius: 0;
    background: none;
    border: none;
    padding-top: 12px;
}

.formio-component-datagrid .datagrid-table, .formio-component-datagrid .datagrid-table td, .formio-component-datagrid .datagrid-table th {
    border: 1px solid #d1d2d4 !important;
}

.formio .btn-primary, .formio-dialog .btn-primary {
    background-color: rgb(41 121 255);
    padding: 12px 18px;
}

.formio .card-header, .formio-dialog .card-header {
    background: none;
    border-bottom: 1px solid #e9ecef;
    border-radius: 0;
}

.formio .card-header:first-child, .formio-dialog .card-header:first-child {
    border-radius: 0;
}

.formio .card-header-tabs {
    border: none;
    border-radius: 0;
    color: rgb(134 142 150);
    border-bottom: 1px solid white;
}

.formio.card {
    border-radius: 0;

    .card-body {
        border: 1px solid #e9ecef;
        border-radius: 2px;
    }
}

.formio .card-header {
    margin-bottom: 20px;
}

.formio .card-header-tabs .nav-link {
    border: none;
    border-radius: 0;
    color: rgb(134 142 150);
    border-bottom: 2px solid white;
    padding: 15px 20px;
}

.formio .card-header-tabs .nav-link.active, .formio-dialog .card-header-tabs .nav-link.active {
    border: none;
    border-radius: 0;
    color: rgb(52 58 64);
    border-bottom: 2px solid rgb(41 121 255);
}

.formio .nav-tabs .nav-link:hover,
.formio .nav-tabs .nav-link:focus,
.formio .nav-tabs .nav-link:focus-visible,
.formio-dialog .nav-tabs .nav-link:hover,
.formio-dialog .nav-tabs .nav-link:focus
.formio-dialog .nav-tabs .nav-link:focus-visible {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid rgb(41 121 255);
}

.formio .form-check, .formio-dialog .form-check {
    padding: 10px 0;
    margin-bottom: 15px;
}

.formio .form-check-input[type=checkbox], .formio-dialog .form-check-input[type=checkbox] {
    border-radius: 0;
    margin-left: 0;
    margin-right: 25px;
}

.formio input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid currentColor;
    border-radius: 0;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}

.formio input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: white;
}

.formio input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.formio input[type="checkbox"]:focus {
    outline: none;
    border: none;
    box-shadow: 0 0 0 1px #919191;
}

.formio .form-check {
    border-bottom: 1px solid #e9ecef;
}

.formio .form-radio {
    margin-top: 20px;
}

.formio {
    .choices__list--multiple .choices__item {
        background-color: #2979ff;
        padding: 6px 14px;
        border: none;
        border-radius: 4px;
    }
}

.formio {
    .formio-form .formio-component-radio .form-radio {
        display: flex;
        list-style-type: none;
        margin: 25px 0 0 0;
        padding: 0;

        .form-check {
            display: flex;
            align-items: center;
            margin: 0 12px 0 0;
            width: 100%;
            height: 60px;
            position: relative;
            border-bottom: none;

            label, input {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            input[type="radio"] {
                opacity: 0.011;
                z-index: 100;
            }

            label {
                border: 1px solid rgb(233 236 239);
                border-radius: 5px;
                padding: 18px;
                cursor: pointer;
                z-index: 90;
                color: rgb(73 80 87);
            }

            label::before {
                color: rgb(173 181 189);
                position: absolute;
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18px' height='18px' viewBox='0 0 24 24' %3E%3Cpath fill='none' d='M0 0h24v24H0V0z' /%3E%3Cpath fill='%23ADB5BD' d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z'/%3E%3C/svg%3E ");
                right: 15px;
                top: 20px;
                width: 25px;
                height: 25px;
            }

            label:hover {
                border-color: rgb(41 121 255);
                color: rgb(41 121 255);
            }

        }

        .radio-selected label {
            background: rgb(234 242 255);
            border: 1px solid rgb(41 121 255);
            color: rgb(41 121 255);
        }

    }

    table tr th {
        padding: 18px 10px !important;
    }

    .four-rows-wrapper {
        table tr:nth-child(4n+5) {
            border-top: 2px solid rgb(208, 208, 208);
        }
    }

    .three-rows-wrapper {
        table tr:nth-child(3n+4) {
            border-top: 2px solid rgb(208, 208, 208);
        }
    }

    .two-rows-wrapper {
        table tr:nth-child(2n+3) {
            border-top: 2px solid rgb(208, 208, 208);
        }
    }
}

.formio-component-modal-wrapper-datagrid {
    .open-modal-button {
        border: 1px solid #e9ecef;
        border-radius: 2px;
    }

    label.control-label {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    button {
        &:hover {
            background: var(--bs-btn-bg) !important;
        }

        table {
            th {
                width: 40% !important;
                text-align: left;
                font-size: 12px;
                font-weight: normal;
            }

            td {
                padding: 0;
                width: 40% !important;

                input {
                    padding: 5px 10px;
                    width: 100%;
                }
            }
        }
    }
}

.report-date-range input::placeholder,
#report-quarter .mr-3 {
    color: #495057;
}

.cruncher-highlight {
    background: yellow;
}

.workload__echart--boundary {
    margin-top: 1em;
    max-height: 550px;
    overflow-y: auto;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome  */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}
