.nice-horizontal-scroll-bar::-webkit-scrollbar {
    height: 6px;
}

.nice-horizontal-scroll-bar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.09);
    border-radius: 5px;
}

.nice-horizontal-scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.12);
}
