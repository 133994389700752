.remirror-theme {
  .remirror-editor-wrapper {
    padding: 0 !important;
  }

  .remirror-editor {
    box-shadow: none !important;

    &.input {
      min-height: 55px !important;
    }
  }
}
